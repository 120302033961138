import React, { useState } from 'react';
import './App.css';

const App = () => {
    const [text, setText] = useState('');
    const [isDissolving, setIsDissolving] = useState(false);
    const [affirmation, setAffirmation] = useState('');

    const handleTextChange = (e) => {
        setText(e.target.value);
    };

    const handleVoiceInput = () => {
        if ('webkitSpeechRecognition' in window) {
            const recognition = new window.webkitSpeechRecognition();
            recognition.onresult = (event) => {
                setText(event.results[0][0].transcript);
            };
            recognition.start();
        } else {
            alert('Speech recognition is not supported in this browser.');
        }
    };

    const handleForget = () => {
        setIsDissolving(true);
        //setAffirmation("Letting go is the first step to freedom!"); // Set your affirmation here
        setTimeout(() => {
            setText('');
            setIsDissolving(false);
            setAffirmation(''); // Clear affirmation after dissolving
        }, 2000); // Adjust duration as needed
    };

    return (
        <div className="container">
            <div className={`full-paper ${isDissolving ? 'dissolve' : ''}`}>
                <textarea
                    value={text}
                    onChange={handleTextChange}
                    placeholder="Leave your thoughts here..."
                />
                {isDissolving && <div className="affirmation">{affirmation}</div>}
            </div>
            <div className="controls">
                <button onClick={handleVoiceInput}>🎤 Talk It Out</button>
                <button onClick={handleForget}>Unburden Yourself</button>
            </div>
        </div>
    );
};

export default App;
